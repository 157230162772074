/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import View from './View';
import Spacer from 'orbiter-web-components/src/components/spacer/Spacer';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const titleCss = css`
    padding: 5px;
    max-width: 1000px; 
    margin-left: auto; 
    margin-right: auto;
    font-size: 40px;
    font-weight: 600;
    line-height: 35px;
`;

const ANIMATION_DURATION = 1;

const textFadeCss = (fade: number) => css`
    opacity: ${fade < ANIMATION_DURATION ? (fade/ANIMATION_DURATION).toString() : "1"};
`;

class Welcome extends React.Component<{
    doneWithWelcome: () => void,
}, {
    t: number,
}> {

    private interval;

    constructor(a: any) {
        super(a);
        this.state = {
            t: 0,
        };
    }

    componentDidMount() {
        const deltaT = 10; // update every deltaT ms
        this.interval = setInterval(() => this.setState({ t: this.state.t + deltaT }), deltaT);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    public render() {
        return this.renderPageWelcome();
    }

    private renderPageWelcome() {
        return (
            <View>
                <Spacer height="40vh" />
                <div css={titleCss}>
                    {this.renderT()}
                </div>
            </View>
        )
    }

    private renderT(): React.ReactNode {
        const timeInSeconds = this.state.t / 1000;

        const timed = [
            [3, "Liefste Els"],
            [3, "dit is een speciaal cadeau"],
            [3, "een cadeau speciaal voor jou"],
            [3, "dit is een cadeau dat blijft geven"],
            [3, <span css={css`font-size: 12px;`}><i>(eigenlijk een beetje zoals mij dus </i>😉<i>)</i></span>],
            [3, <span>elke dag van je 25<sup>e</sup> jaar,</span>],
            [3, "zal dit cadeau je voorzien van een ander cadeau."],
            [3, "Ik wens je een héél"],
            [3, "hééééééééééééééééééél"],
            [3, "héééééééééééééééééééééél"],
            [3, "héél gelukkige verjaardag!"],
            [3, "🧸"],
            [3, "😘"],
            [3, "❤"],
            [3, <i css={css`font-size: 12px;`}>- Daan, 12 november 2020</i>],
        ];

        let cumulativeDuration = 0;
        for (let index = 0; index < timed.length; index++) {
            const set = timed[index];
            const setDuration: number = set[0] as number;
            const setStart: number = cumulativeDuration;
            const setEnd: number = cumulativeDuration + setDuration;
            cumulativeDuration = setEnd;
            if(timeInSeconds < cumulativeDuration){
                return <p css={textFadeCss((timeInSeconds - setStart) < (setEnd - timeInSeconds) ? (timeInSeconds - setStart) : (setEnd - timeInSeconds))}>{set[1]}</p>;
            }   
        }
        this.props.doneWithWelcome();
        return <p>...</p>;
    }

}

export default Welcome;
