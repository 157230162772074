/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Page from 'orbiter-web-components/src/components/page/Page';
import Theme, { applyTheme } from 'orbiter-web-components/src/components/Theme';
import { ThemeProvider } from 'emotion-theming';
import { ProjectTheme } from '../Theme';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Welcome from './Welcome';
import View from './View';
import Spacer from 'orbiter-web-components/src/components/spacer/Spacer';
import { getToday, greetingDescriptionGenerator, greetingGenerator } from './helpers';
import data, { IDay } from './data';
import ReactPhotoCollageContainer from "../react-photo-collage/react-photo-collage-container";
import Confetti from 'react-dom-confetti';
import Album from './Album';
const axios = require('axios');

export const defaultStyling = (theme: Theme) => {
    return css`
        color: ${applyTheme(theme, (t: Theme) => t.colors.primaryText)};
        line-height: ${applyTheme(theme, (t: Theme) => t.font.lineHeight.default)};
        font-family: ${applyTheme(theme, (t: Theme) => t.font.fontFamily.default)};
        font-size: ${applyTheme(theme, (t: Theme) => t.font.fontSize.default)};
        h1{
            font-weight: bold;
        }
        * {
            outline: none !important;
            cursor: none;
        }

    `;
};

export const fullHeightStyling = () => {
    return css`
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: right;
        margin-right: 20%;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            text-align: left;
            margin-right: auto;
            padding-right: 40px !important;
            padding-left: 40px !important;
        }
    `;
};

export const backgroundStyling = (theme: Theme) => {
    return css`
        margin-right: 40%;
        box-shadow: inset -200px 0 100px #fff;
        @media (max-width: ${applyTheme(theme, (t: Theme) => t.mobileWidth.minWidth)} )  {
            margin-right: 0;
            box-shadow: none;
        }
    `;
};

const presentOfTheDayCss = (day: IDay) => {
    return css`
        ${(() => {
            if (day?.backgroundImage) {
                return css`
                    background-image: url("${data.general.backgroundImage}");
                    background-size: cover;
                `;
            }
            return '';
        })()}
        background-color: white;
    `;
};

export const buttonCss = css`
    background-color: white;
    border-width: 0px;
    border-radius: 5px; 
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12)
        ;
    padding: 20px;
    margin: 5px;

    &:hover, &:active{
        background-color: lightgray;
    }
    `;

class Home extends React.Component<any, {
    welcome: boolean,
    album: boolean,
    presentOfTheDay: boolean,
    date: Date,

    confetti: boolean,
}> {

    private interval;
    private lastUpdateKey = null;

    constructor(a: any) {
        super(a);
        this.state = {
            welcome: true,
            album: false,
            presentOfTheDay: false,

            date: new Date(),

            confetti: false,
        };
    }

    componentDidMount() {
        const deltaT = 1800000; // update every deltaT ms (30 minutes)
        this.interval = setInterval(() => {
            this.setState({date: new Date()});
            console.log("Checking for update");
            axios.get('/cdn/forcerefresh.txt?t=' + Date.now())
            .then((response) => {
                try {
                    const updateKey = response.data;
                    if (this.lastUpdateKey == null) {
                        this.lastUpdateKey = updateKey;
                    } else if (updateKey != this.lastUpdateKey) {
                        console.log("Running update");
                        console.log("Update key was", this.lastUpdateKey, "is updated to", updateKey);
                        this.lastUpdateKey = updateKey;
                        location.reload(true);
                    }
                } catch (e) { 
                    console.error(e);
                }
                })
        }, deltaT);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    public render() {
        return (
            <ThemeProvider theme={ProjectTheme}>
                <Page
                    pageStyling={defaultStyling}>
                    {this.renderPage()}
                </Page>
            </ThemeProvider>

        );
    }

    private renderPage() {
        if (this.state.welcome) {
            return this.renderPageWelcome();
        }
        if (this.state.album) {
            return this.renderPageAlbum();
        }
        if (this.state.presentOfTheDay) {
            return this.renderPagePresentOfTheDay();
        }
        return <View>
            <Spacer height="20vh" />
            <h1>{greetingGenerator()}</h1>
            <p>{greetingDescriptionGenerator()}</p>
            <Spacer height="20vh" />
            <button css={buttonCss} onClick={() => this.setState({ presentOfTheDay: true, confetti: false, })}>Open je cadeau</button>
            <button css={buttonCss} onClick={() => this.setState({ album: true, })}>Album</button>
        </View>;
    }

    private renderPageWelcome() {
        return <Welcome doneWithWelcome={() => this.setState({ welcome: false })} />;
    }

    private renderPageAlbum() {
        return <Album doneWithAlbum={() => this.setState({ album: false })} images={data.album.images} />;
    }

    private renderPagePresentOfTheDay() {
        const day: IDay = getToday();
        setTimeout(() => {
            this.setState({ confetti: true })
        }, 500);

        const setting = {
            width: '600px',
            height: ['300px', '150px'],
            layout: [1, 4],
            showNumOfRemainingPhotos: true
        };

        const config = {
            angle: 90,
            spread: 360,
            startVelocity: 40,
            elementCount: 70,
            dragFriction: 0.12,
            duration: 3000,
            stagger: 3,
            width: "10px",
            height: "10px",
            perspective: "500px",
            colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
        };

        return <View containerStyling={() => presentOfTheDayCss(day)}>
            {/* <Spacer height="20vh" /> */}
            <h1>{day.title}</h1>
            <p css={css`padding-left: 100px; padding-right: 100px;`}>{day.content}</p>
            <Confetti css={css`position: absolute; top: 40px; left: 400px; z-index: 100;`} active={this.state.confetti} config={config} />
            {(() => {
                if (day.images.length > 1) {
                    return <div css={css`width: 600px; margin-left: auto; margin-right: auto;`}>
                        <ReactPhotoCollageContainer {...setting} photos={day.images.map(i => { return { src: i } })} />
                    </div>;
                }else if(day.images.length == 1){
                    return <div css={css`width: 600px; margin-left: auto; margin-right: auto;`}>
                        <img src={day.images[0]} css={css`width: 100%;`}></img>
                    </div>;
                }
                return "";
            })()}
            <Spacer height="20vh" />
            <button css={buttonCss} onClick={() => this.setState({ presentOfTheDay: false })}>Terug</button>
        </View>;
    }

}

export default Home;
