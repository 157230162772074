/** @jsx jsx */

import * as React from 'react';
import { jsx, css, Interpolation } from '@emotion/core';
import Theme from 'orbiter-web-components/src/components/Theme';

export const containerStyling = (theme: Theme) => {
    return css`
        padding: 0;
        margin: 0;
        height: 100vh;
    `;
};

export const contentContainerStyling = (theme: Theme) => {
    return css`
        text-align: center;
        padding: 30px;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    `;
};

export interface ViewProps {
    children: React.ReactNode;

    containerStyling?: (theme: Theme) => Interpolation;
    contentContainerStyling?: (theme: Theme) => Interpolation;
    logoStyling?: (theme: Theme) => Interpolation;
}

export default class View extends React.Component<ViewProps, any> {
    public render() {
        return (
            <div css={(t: Theme) => [containerStyling(t), this.props.containerStyling !== undefined ? this.props.containerStyling(t) : undefined]}>
                <div css={(t: Theme) => [contentContainerStyling(t), this.props.contentContainerStyling !== undefined ? this.props.contentContainerStyling(t) : undefined]}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
