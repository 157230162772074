interface IData {
    general: any;
    album: { images: string[] };
    days: IDay[];
    fallback: IDay;
}

export interface IDay {
    year: number,
    month: number,
    day: number,
    backgroundImage: null | string,
    title: string,
    content: React.ReactNode,
    images: string[],
}

const data: IData = {
    general: {
        backgroundImage: "/cdn/images/background.jpg",
    },
    album: {
        images: [
            "/cdn/images/album/20201111/IMG_20200821_041908.jpg",
            "/cdn/images/album/20201111/IMG-20201013-WA0024.jpg",
            "/cdn/images/album/20201111/IMG-20200924-WA0015.jpg",
            "/cdn/images/album/20201111/IMG_20200903_221528.jpg",
            "/cdn/images/album/20201111/IMG_20200903_221536.jpg",
            "/cdn/images/album/20201111/IMG_20200903_221553.jpg",
            "/cdn/images/album/20201111/IMG_20200903_221606.jpg",
            "/cdn/images/album/20201111/IMG_20200903_221724.jpg",
            "/cdn/images/album/20201111/IMG_20200905_184847.jpg",
            "/cdn/images/album/20201111/Screenshot_20201009-002420.jpg",
            "/cdn/images/album/20201111/IMG_20200905_184859.jpg",
            "/cdn/images/album/20201111/IMG-20200924-WA0016.jpg",
            "/cdn/images/album/20201111/IMG_20200916_131827.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135634.jpg",
            "/cdn/images/album/20201111/IMG_20200903_091522.jpg",
            "/cdn/images/album/20201111/IMG_20200822_212008.jpg",
            "/cdn/images/album/20201111/IMG_20200826_034547.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135636.jpg",
            "/cdn/images/album/20201111/IMG_20200821_041941.jpg",
            "/cdn/images/album/20201111/IMG_20200821_041937.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135654.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135659.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135716.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135723.jpg",
            "/cdn/images/album/20201111/IMG_20200923_135729.jpg",
            "/cdn/images/album/20201111/IMG_20200927_181850.jpg",
            "/cdn/images/album/20201111/IMG_20200927_185213.jpg",
            "/cdn/images/album/20201111/IMG_20200822_211955.jpg",
            "/cdn/images/album/20201111/IMG_20200927_185217.jpg",
            "/cdn/images/album/20201111/IMG_20200927_185225.jpg",
            "/cdn/images/album/20201111/IMG_20200927_203640.jpg",
            "/cdn/images/album/20201111/IMG_20200927_214948.jpg",
            "/cdn/images/album/20201111/IMG_20200903_221556.jpg",
            "/cdn/images/album/20201111/IMG-20200924-WA0014.jpg",
            "/cdn/images/album/20201111/IMG-20200924-WA0017.jpg",
            "/cdn/images/album/20201111/Screenshot_20201009-002404.jpg",
        ],
    },
    fallback: {
        year: 0,
        month: 0,
        day: 0,
        backgroundImage: null,
        title: "Oeps",
        content: "Oei, er liep iets mis. Bel daan!",
        images: [
            // "/cdn/images/background.jpg",
            // "/cdn/images/background.jpg",
            // "/cdn/images/background.jpg",
        ],
    },
    days: [
        {
            year: 2020,
            month: 11,
            day: 12,
            backgroundImage: null,
            title: "Happy Birthday!",
            content: "Dag liefste schat, één cadeau is zo stom, het mogen er veel meer zijn, en niet alleen op je verjaardag! Daarom heb ik speciaal voor jou een magisch kader gemaakt (ik noem het de EDA 3000) dat je elke dag opnieuw verrast met een nieuw cadeau. En dan krijg je er natuurlijk een heel dagje samen bovenop! Geniet van je verjaardag!",
            images: [
                // "/cdn/images/background.jpg",
                // "/cdn/images/background.jpg",
                // "/cdn/images/background.jpg",
                // "/cdn/images/background.jpg",
                // "/cdn/images/background.jpg",
                // "/cdn/images/background.jpg",
                // "/cdn/images/background.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 13,
            backgroundImage: null,
            title: "Woef woef!",
            content: "Tadaaaa! Puppies in overvloed! Wat een dropjes!",
            images: [
                "/cdn/images/messages/20201113/puppy6.jpeg",
                "/cdn/images/messages/20201113/puppy7.jpg",
                "/cdn/images/messages/20201113/puppy5.jpg",
                "/cdn/images/messages/20201113/puppy4.jpg",
                "/cdn/images/messages/20201113/puppy3.jpg",
                "/cdn/images/messages/20201113/puppy2.jpg",
                "/cdn/images/messages/20201113/puppy1.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 14,
            backgroundImage: null,
            title: "To the moon and back!",
            content: "Vandaag is het precies 51 jaar geleden dat de Apollo 12 koers zette naar de maan. En terug! Als dat geen toeval is, want ... I love you to the moon and back! Geniet van je tweede extra verjaardag liefje!",
            images: [
                "/cdn/images/messages/20201114/tothemoonandback.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 15,
            backgroundImage: null,
            title: "Knuuuuffffeeeeelllllssss",
            content: "Oh nooo, ik heb je al meer dan 24u niet kunnen knuffelen! Ik mis het nu al, en aangezien we toch hetzelfde denken vermoed ik dat jij die knuffels ook al mist, dus hier is een grote dikke virtuele knuffelbeer met veel knuffels voor jou!",
            images: [
                "/cdn/images/messages/20201115/hug.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 16,
            backgroundImage: null,
            title: "Smile!",
            content: "Blue monday? Nee nog niet voor nu! Maak er maar een happy monday van! En aangezien ik pas blij ben als jij ook blij bent, heb ik een paar foto's gezocht die je zeker gaan doen lachen!",
            images: [
                "/cdn/images/messages/20201116/funny4.jpg",
                "/cdn/images/messages/20201116/funny3.jpg",
                "/cdn/images/messages/20201116/funny2.jpeg",
                "/cdn/images/messages/20201116/funny1.jpg",
                "/cdn/images/messages/20201116/funny5.jpg",
                "/cdn/images/messages/20201116/funny6.jpg",
                "/cdn/images/messages/20201116/funny7.jpg",
                "/cdn/images/messages/20201116/funny8.jpg",
                "/cdn/images/messages/20201116/funny9.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 17,
            backgroundImage: null,
            title: "Happy birthday to you!",
            content: "Hey liefje! Patrick Plantmans was je verjaardag helemaal vergeten, schandalig toch! Maar hij wil het echt heel graag terug goed maken. Hij heeft gezegd dat hij 'happy birthday' gaat zingen wanneer hij je ziet. Ik hoop dat dat lukt, want je weet ook dat hij soms een beetje verlegen is. Maar goed, hij wenst je super super superveel zonnestraaltjes!",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 18,
            backgroundImage: null,
            title: "Love",
            content: "Martin Luther King, Jr. staat bekend voor zijn uitspraak \"I have decided to stick to love; hate is too great a burden to bear.\". En hij heeft helemaal gelijk. Dus verwacht je maar aan veel liefde schatje, want ik hou van jou. ❤",
            images: [
                "/cdn/images/messages/20201118/heart.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 19,
            backgroundImage: null,
            title: "Op nog vele weken!",
            content: "Wauwie! Nu ben je al een week 25 jaar! Hopelijk ben je er al een beetje aan gewoon! Maar weet je wat nog beter is? Nu ben je alweer een week langer mijn zonnestraaltje! Love you, sunshine of mine!",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 20,
            backgroundImage: null,
            title: "Happy birthday!",
            content: "Neeeeeee, niet je gewone verjaardag, die was vorige week. Dit keer is het tijd om een van onze verjaardagen te vieren! Vier maanden geleden kwam ik mijn favoriete patatje (en daar ga ik niet licht over ;) ) tegen in de aardappelen-rayon in de Delhaize. Die dag had ik niet durven dromen wat we vandaag zouden hebben. Ik hou ongelooflijk veel van jou lieve schat! xxx",
            images: [
                "/cdn/images/messages/20201120/patatje.png",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 21,
            backgroundImage: null,
            title: "Weekend!!!!!",
            content: "Jaaa weekend! Een van je laatste werkloze weekends. Dat moeten we vieren! Maar aangezien ik er niet ben heb ik een paar leuke gifs uitgezocht om jou aan te moedigen er ten volle van te genieten!",
            images: [
                "/cdn/images/messages/20201121/gif1.gif",
                "/cdn/images/messages/20201121/gif2.gif",
                "/cdn/images/messages/20201121/gif3.gif",
                "/cdn/images/messages/20201121/gif4.gif",
                "/cdn/images/messages/20201121/gif5.gif",
                "/cdn/images/messages/20201121/gif6.gif",
                "/cdn/images/messages/20201121/gif7.gif",
                "/cdn/images/messages/20201121/gif8.gif",
                "/cdn/images/messages/20201121/gif9.gif",
                "/cdn/images/messages/20201121/gif10.gif",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 22,
            backgroundImage: null,
            title: "Een euro",
            content: "Als ik elke keer wanneer ik aan jou dacht een euro kreeg, liefje, dan was ik nu de rijkste man ter wereld. Maar niet getreurd, je maakte van mij al de gelukkigste man ter wereld.",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 23,
            backgroundImage: null,
            title: "E. E. Cummings",
            content: "\"You are my sun, my moon, and all my stars.\"",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 24,
            backgroundImage: null,
            title: "Weekend!!!!!",
            content: "Jaaa weekend (eigenlijk niet hoor, deze was van in het weekend, maar omdat je die gemist hebt is ie hier nog eens, speciaal voor jou! ;D )! Een van je laatste werkloze weekends. Dat moeten we vieren! Maar aangezien ik er niet ben heb ik een paar leuke gifs uitgezocht om jou aan te moedigen er ten volle van te genieten!",
            images: [
                "/cdn/images/messages/20201121/gif1.gif",
                "/cdn/images/messages/20201121/gif2.gif",
                "/cdn/images/messages/20201121/gif3.gif",
                "/cdn/images/messages/20201121/gif4.gif",
                "/cdn/images/messages/20201121/gif5.gif",
                "/cdn/images/messages/20201121/gif6.gif",
                "/cdn/images/messages/20201121/gif7.gif",
                "/cdn/images/messages/20201121/gif8.gif",
                "/cdn/images/messages/20201121/gif9.gif",
                "/cdn/images/messages/20201121/gif10.gif",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 25,
            backgroundImage: null,
            title: "Heeft iemand je dat al verteld?",
            content: "Heeft iemand je vandaag al verteld wat voor een prachtige persoon jij bent? Je hebt zoveel respect voor mij en voor anderen. Ik ben enorm blij dat we elkaar gevonden hebben. Heel veel kusjes, en geniet van je dag, allerliefste schat!",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 26,
            backgroundImage: null,
            title: "De tijd vliegt.",
            content: "Uh wat?? De tijd gaat wel echt heel snel! Nu ben je al twee weken 25 jaar! Maar ik durf wedden dat je in die hele 25 jaar nog nooit zoveel cadeautjes hebt gekregen. Maar toch, hier is er nog eentje:",
            images: [
                "/cdn/images/messages/20201126/gift.gif",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 27,
            backgroundImage: null,
            title: "Goed bezig liefje!",
            content: "Dit moest ik er toch even tussen wringen. Je bent echt supergoed bezig liefje. Ik ben fier op jou. Ik vind het echt knap hoe jij je interview van gisteren hebt aangepakt, en hoe goed je je voorbereid hebt. En je ziet, al die stress was nergens voor nodig. En dat wist ik. Want je doet dat goed. Supergoed. Super super super goed. xxx",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 28,
            backgroundImage: null,
            title: "Ik sta achter jou.",
            content: "I've got your back. Te cubro las espaldas. Je te couvre. Tens o meu apoio. Ti copro le spalle.",
            images: [
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 29,
            backgroundImage: null,
            title: "Herken je ze nog?",
            content: "Vandaag exact vier maanden geleden stuurden we onze eerste selfies naar elkaar. Ik was toen de gelukkigste persoon ter wereld (I mean...kijk naar je foto!), en dat ben ik nu nog steeds! Ik denk nog een beetje meer zelfs, kan dat? Ik was toen al verliefd op je stralende lach en je glinsterende ogen. Dankje liefje! xxx",
            images: [
                "/cdn/images/messages/20201129/foto.jpg",
            ],
        },
        {
            year: 2020,
            month: 11,
            day: 30,
            backgroundImage: null,
            title: "The Beatles",
            content: "\"All you need is love.\"",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 1,
            backgroundImage: null,
            title: "Hihi",
            content: "Nog precies vier maanden en dan is het 1 april, maar ik kan niet zo lang wachten op al die flauwe mopjes, dus hier is er al eentje voor jou! :D Er word ingebroken bij een student, de inbreker zegt tegen de student: \"Ik zoek geld!\" Waarop de student zegt \"Wacht eventjes, dan zoek ik met je mee.\"",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 2,
            backgroundImage: null,
            title: "Koud en warm",
            content: "De dagen zijn koud, de nachten zijn donker, dat is hoe het hoort in de winter. Maar toch voelt deze winter zoveel warmer en zoveel lichter dan vroeger. En dat is dankzij jou.",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 3,
            backgroundImage: null,
            title: "Nog veeeeeeeel maandjes",
            content: "De tijd vliegt lief. We zijn alweer een maandje langer samen, en ik vind het nog altijd even geweldig. Oké, nee, dat is gelogen. Ik vind het nóg geweldiger. Er mogen nog heeeeeel veel maandjes volgen! xxx",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 4,
            backgroundImage: null,
            title: "Aftellen naar Kerst!",
            content: "Wat is er beter dan hondjes? Schattige hondjes verkleed als kerstcadeautjes natuurlijk! Speciaal voor jou, een uitgebreide selectie!",
            images: [
                "/cdn/images/messages/20201202/dog01.jpg",
                "/cdn/images/messages/20201202/dog02.jpg",
                "/cdn/images/messages/20201202/dog03.jpg",
                "/cdn/images/messages/20201202/dog04.jpg",
                "/cdn/images/messages/20201202/dog05.jpg",
                "/cdn/images/messages/20201202/dog06.jpg",
                "/cdn/images/messages/20201202/dog07.jpg",
                "/cdn/images/messages/20201202/dog08.jpg",
                "/cdn/images/messages/20201202/dog09.jpg",
                "/cdn/images/messages/20201202/dog10.jpg",
                "/cdn/images/messages/20201202/dog11.jpg",
                "/cdn/images/messages/20201202/dog12.jpg",
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 5,
            backgroundImage: null,
            title: "I love you",
            content: "\"I've tried so many times to think of a new way to say it, and it's still 'I love you'.\" - Zelda Fitzgerald, Daan Lenaerts",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 6,
            backgroundImage: null,
            title: "De Sint is akkoord!",
            content: "Hey liefje, de Sint heeft me het in zijn grote boek laten lezen. Er staat niet alleen in dat je super braaf bent geweest dit jaar, maar ook dat jij het beste liefje ooit bent. Hij volgt je al heel je leven, dus hij kan het weten!",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 7,
            backgroundImage: null,
            title: "Wauw!",
            content: "Je bent nu al 25 dagen 25 jaar, als dat niet mooi is! Maar dat wil ook zeggen dat je de afgelopen 25 dagen al 25 cadeautjes hebt gekregen. Maar weet je wat het allerbeste is? Jij verdient dat, voor de volle 1000 procent. Vele verjaardagskusjes! xxx",
            images: [
            ],
        },
        {
            year:2020,
            month: 12,
            day: 8,
            backgroundImage: null,
            title: "xxx",
            content: "Elk kusje van jou maakt mijn dag. Of het nu tijdens het eten is, of als ik aan het werken ben, of voor het slapen, of zelfs in het midden van de nacht. Daarom krijg je vandaag een hele hoop kusjes van mij. Niet enkel virtueel, ook in het echt! Maar hier zijn de virtuele al: xxxxxxxxxxxxxxx...xxxxxxx...xxxx...10-jaar-later...xxxxxxxxxxxxxx",
            images: [],
        },
        {
            year:2020,
            month: 12,
            day: 9,
            backgroundImage: null,
            title: "Worth it",
            content: "Een bekende quote van Franklin Jones gaat als volgt: \"Love doesn't make the world go round. Love is what makes the ride worthwhile.\" Ik ben vooral blij dat ik mijn momenten op deze aarde met jou kan delen.",
            images: [],
        },
        {
            year:2020,
            month: 12,
            day: 10,
            backgroundImage: null,
            title: "Nobel Prijs voor het Beste Liefje",
            content: "Vandaag, in 1901, werden de eerste Nobel prijzen uitgereikt. Toen ging het over prijzen in het gebied van eenvoudige dingen als fysica, chemie, geneeskunde, vrede, ... Vandaag gaat het om een prijs die veel meer waarde heeft. De Nobel Prijs voor het Beste Liefje. En bij deze reik ik 'm uit aan jou, mijn beste liefje. Geniet ervan.",
            images: [],
        },
        {
            year:2020,
            month: 12,
            day: 11,
            backgroundImage: null,
            title: "Ik hou zooooooooveeeel van jou",
            content: "I love you with all my belly. I would say heart, but my belly is bigger.",
            images: [],
        },
        {
            year:2020,
            month: 12,
            day: 12,
            backgroundImage: null,
            title: "Wohooo een maand!",
            content: "De eerste maand van je 25e jaar zit er al op, de tijd gaat zo snel! Maar gelukkig blijven er nog 11 maanden over waarop ik graag samen met jou elke dag je 25e verjaardag vier!",
            images: [],
        },
        {
            year:2020,
            month: 12,
            day: 13,
            backgroundImage: null,
            title: "Woef woef!",
            content: "Wauw, zo lang zonder puppies! Da's veel te lang. Laat me daar snel iets aan doen!",
            images: [
                "/cdn/images/messages/20201213/pup1.jpg",
                "/cdn/images/messages/20201213/pup2.jpg",
                "/cdn/images/messages/20201213/pup3.jpg",
                "/cdn/images/messages/20201213/pup4.jpg",
                "/cdn/images/messages/20201213/pup5.jpg",
                "/cdn/images/messages/20201213/pup6.jpg",
                "/cdn/images/messages/20201213/pup7.jpg",
                "/cdn/images/messages/20201213/pup8.jpg",
            ],
        },
        {
            year:2020,
            month: 12,
            day: 14,
            backgroundImage: null,
            title: "Wist je dat al?",
            content: "In een goede relatie breng je het beste in elkaar naar boven. Ik wil nog een stapje verder gaan, door ook het beste in je feitenkennis naar boven te brengen! Dus bij deze: wist je dat een tand van een olifant wel 4kg weegt?! Daarmee win je alvast je volgende quiz!",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 15,
            backgroundImage: null,
            title: "One Direction",
            content: "Neeeee, niet die groep! One direction! \"Loving is not just looking at each other, it's looking in the same direction.\" Laat dat nu net hetgeen zijn waar we oh zo goed in zijn! 😘",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 16,
            backgroundImage: null,
            title: "Hmmmmmmm",
            content: "Njammieeeee, dit is precies 3 maanden geleden! We moeten hier dus dringend terug naar toe. Zodra het kan neem ik je mee! Maar aangezien dat nu nog niet gaat maken we het nog maar eens zelf! Kies maar een momentje. xxx",
            images: [
                "/cdn/images/messages/20201216/pancake.jpg",
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 17,
            backgroundImage: null,
            title: "Vleugels",
            content: "Vandaag in 1903 hebben de broers Wright het eerste vliegtuig van de grond gekregen. Maar eigenlijk is dat maar klein bier in vergelijking met ons. Vliegtuigen geraken slechts een paar kilometer hoog, nog niet eens voorbij de dampkring, terwijl ik me bij jou in de zevende hemel waan.",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 18,
            backgroundImage: null,
            title: "Ik hou van jou",
            content: "Ik hou vandaag meer van jou dan gisteren, maar niet zoveel als morgen.",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 19,
            backgroundImage: null,
            title: "Zo schattig!",
            content: "Het moeten niet altijd schattige hondjes zijn, kijk naar dit dropje! Speciaal voor jou.",
            images: [
                "/cdn/images/messages/20201219/eekhoorn.jpg",
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 20,
            backgroundImage: null,
            title: "Wauw, weeral de 20e!",
            content: "De tijd gaat zo snel, het is alweer de 20e! Ik vind het nog steeds geweldig, steeds geweldiger eigenlijk, dat we elkaar vijf maanden geleden zijn tegengekomen. Ik hou superveel van jou. Elke dag meer. xxx",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 21,
            backgroundImage: null,
            title: "xxx",
            content: "Take my hand, my heart, and my soul. Because it is meant for you. Ik hou enorm hard van jou liefje. xxx",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 22,
            backgroundImage: null,
            title: "Kusjes voor mijn liefje!",
            content: "Kusje kusje kusje kusje kusje ... kusje kusje kusje ... kusje kusje kusje ... ik kan niet stoppen ... kusje kusje kusje ... want ik zie je ... kusje kusje ... veel ... kusje kusje kusje kusje kusje ... te graag ... kusje kusje kusje ... want je bent mijn ... kusje kusje kusje kusje ... allerliefste ... kusje kusje kusje ... schatje ... kusje kusje kusje",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 23,
            backgroundImage: null,
            title: "Kerstsfeer!",
            content: "Het mooiste aan deze tijd van het jaar is toch de gezellige sfeer met alle lichtjes he! Daarom presenteer ik aan mijn liefste schatje vandaag een paar van onze mooiste foto's met de meest gezellige lampjes, van precies een maand geleden! Geniet ervan liefje! xxx",
            images: [
                "/cdn/images/messages/20201223/foto05.jpg",
                "/cdn/images/messages/20201223/foto01.jpg",
                "/cdn/images/messages/20201223/foto02.jpg",
                "/cdn/images/messages/20201223/foto03.jpg",
                "/cdn/images/messages/20201223/foto04.jpg",
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 24,
            backgroundImage: null,
            title: "Nog één dagje",
            content: "Nog één dagje tot Kerstmis, maar ook tot je mijn ouders ontmoet. Ik weet dat je hier wel stress voor hebt. Maar ik weet ook heel zeker dat dat echt niet nodig is. Je bent een lief en mooi meisje, en je bent het beste gezelschap wat iemand kan wensen. Dus no worries liefje! xxx",
            images: [],
        },
        {
            year: 2020,
            month: 12,
            day: 25,
            backgroundImage: null,
            title: "Knuffeltjessssss",
            content: "Knuffels zijn uitgevonden om iemand te laten weten dat je van hen houdt zonder iets te zeggen. Ik wil je overladen met knuffels!",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 26,
            backgroundImage: null,
            title: "Bedankt liefje",
            content: "Bedankt liefje, dat je Kerst bij mijn thuis wou vieren. Ik hoop dat het een beetje is meegevallen, eerlijke feedback is altijd super welkom, dat weet je. Ik ben echt fier op de manier waarop je hiermee bent omgegaan. Een beetje stress, dat is waar, maar zie je wel dat het gelukt is! Ik hou enorm veel van jou. Op nog heeeeeel veel Kersten samen. xxx",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 27,
            backgroundImage: null,
            title: "Eventjes tussendoor",
            content: "Dit moest ik er toch eventjes tussen duwen liefje, maar ik wil nog eens laten weten hoe geweldig ik de afgelopen twee dagen met jou vond. Ik heb van elke minuut met jou genoten. En je cadeau was, inderdaad, echt perfect. Echt super super goed gevonden. Je kent me echt goed. Ik ben super fier op jou, en ik hou echt zielsveel van jou.",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 28,
            backgroundImage: null,
            title: "Mijn favoriete plekje",
            content: "Je kent mij supergoed liefje, maar weet je ook wat mijn favoriete plekje op deze aardbol is? xxx",
            images: [
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 29,
            backgroundImage: null,
            title: ";)",
            content: "",
            images: [
                "/cdn/images/messages/20201229/todo.jpg",
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 30,
            backgroundImage: null,
            title: "Heel veel succes!",
            content: "Super super super veel succes met je interview vandaag liefje! Ik geloof in jou, je gaat dat echt goed doen, daar heb ik alle vertrouwen in, en anders weten ze bij Materialise echt niet waar ze mee bezig zijn en wat ze missen. Ik duim voor jou, zo hard als ik kan!",
            images: [
                "/cdn/images/messages/20201230/clover.jpg",
            ],
        },
        {
            year: 2020,
            month: 12,
            day: 31,
            backgroundImage: null,
            title: "2020",
            content: "De laatste dag van 2020, op z'n zachtst gezegd een heel speciaal jaar. Een jaar met zoveel moeilijkheden en zoveel problemen voor zoveel mensen, maar toch een jaar waar ik altijd met een grote glimlach aan zal terugdenken. En de enige reden daarvoor ben jij. Ik ben blij dat we dit rare jaar kunnen afsluiten, maar ik ben nog veel blijer dat ik dat samen met jou kan doen. Ik hou absurd veel van jou, liefste schat, en ik kijk er al naar uit om 2021 helemaal met jou te delen. xxx",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 1,
            backgroundImage: null,
            title: "Goede voornemens",
            content: "Eerst en vooral nog eens een heel gelukkig nieuwjaar liefje, ik wens je het komende jaar alles wat je hartje begeert! En vergeet nooit dat ik er altijd voor jou zal zijn als je een helpend handje kan gebruiken om dat te bereiken. Mijn enige voornemen voor het komende jaar is het best mogelijke vriendje te zijn voor jou, mijn allerliefste schatje. Want dat verdien je echt. Dit jaar kan ik je voor het eerst een heel jaar al mijn liefde geven, en ik kijk er al enorm naar uit. xxx",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 2,
            backgroundImage: null,
            title: "Mijn to-do lijst",
            content: "Er staat al één ding op mijn to-do lijstje voor na de examens: naar je hartslag luisteren. Want als ik die kan horen weet ik dat ik heel dicht bij jou ben.",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 3,
            backgroundImage: null,
            title: "Al vier maanden!",
            content: "De tijd vliegt als je je amuseert, dat is wel duidelijk. We zijn nu al vier maanden samen liefste schat, en ik heb van elke dag genoten. Het is echt geweldig met jou. Als ik eerder had geweten dat je bestond was ik je jaren geleden al komen zoeken. xxx",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 4,
            backgroundImage: null,
            title: "Simpel.",
            content: "Het is heel eenvoudig liefje: ik hou van jou met heel mijn hart. Ik hoop dat je een fijne dag tegemoet gaat.",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 5,
            backgroundImage: null,
            title: "Soulmate",
            content: "Een maand geleden stuurde je dit naar mij, en ik wil het terug met jou delen. Ik vond dit berichtje zo mooi, en zo lief, en ook zo accuraat. Want dit is echt hoe ik naar ons kijk, en dat is echt het meest geweldige ooit. Ik hou van jou, en je bent echt mijn soulmate. xxx",
            images: [
                "/cdn/images/messages/20210105/soulmate.jpg",
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 6,
            backgroundImage: null,
            title: "Morse",
            content: "Vandaag in 1838 demonstreerde Samuel Morse voor het eerst de telegraaf. Als we toen leefden, zou ik je dit berichtje sturen: .. -.- / .... --- ..- / ...- .- -. / .--- --- ..- --..-- / .-.. .. . ..-. .--- . .-.-.-",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 7,
            backgroundImage: null,
            title: "Italië",
            content: "Vier maanden geleden rond deze tijd zat ik in Italië, van de zon, het landschap, en het fijne gezelschap te genieten, maar ook vooral aan jou te denken. Ik wil er heel graag nog eens heen, maar dan met jou. Om je voor te bereiden, en ook gewoon omdat ik weet dat je dat leuk vindt, heb ik speciaal voor jou een compilatie gemaakt van een paar van die leuke Italiaanse hondjes. xxx",
            images: [
                "/cdn/images/messages/20210107/dog01.jpg",
                "/cdn/images/messages/20210107/dog02.jpg",
                "/cdn/images/messages/20210107/dog03.jpg",
                "/cdn/images/messages/20210107/dog04.jpg",
                "/cdn/images/messages/20210107/dog05.jpg",
                "/cdn/images/messages/20210107/dog06.jpg",
                "/cdn/images/messages/20210107/dog07.jpg",
                "/cdn/images/messages/20210107/dog08.jpg",
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 8,
            backgroundImage: null,
            title: "Hahaha",
            content: "In mijn zoektocht naar leuke weetjes kwam ik het volgende tegen, en aangezien ik je graag zie lachen deel ik de titel eventjes met jou: \"President George H.W. Bush vomits on the Prime Minister of Japan\", hahahaha wat?!?! :D",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 9,
            backgroundImage: null,
            title: "Kijk!",
            content: "Vijf maanden geleden maakte ik deze gif. Hij is nog steeds perfect, mijn favoriete gif. Je bent een natuurtalent lief!",
            images: [
                "/cdn/images/messages/20210109/thegif.gif",
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 10,
            backgroundImage: null,
            title: "Bedankt voor alle steun",
            content: "Ik moet het echt nog eens zeggen liefje, want ik vind het echt geweldig hoe hard jij me steunt met alles. Jij staat altijd achter mij, en dat maakt me oprecht enorm gelukkig. Bedankt voor alle steun die je mij geeft tijdens deze examens. Bedankt om er voor mij te zijn.",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 11,
            backgroundImage: null,
            title: "Knuffelverjaardag!",
            content: "Proficiat knuffelbeer-emoji!! Het is vijf maanden geleden dat we onze knuffel-emoji hebben uitgevonden. Dat vraagt om een feestje, met heeeeeel veel knuffels! xxx",
            images: [
                "/cdn/images/messages/20210111/teddy.png",
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 12,
            backgroundImage: null,
            title: "Happy birthday! (v3)",
            content: "Wauw, het is precies twee maanden geleden dat je jarig was. Een hele gelukkige maand-verjaar-dag snoesie. Na de examens wil ik die dubbel en dik met jou vieren. Maar nu krijg je al heel veel kusjes, knuffels en nog veel meer van mij! (En ook eventjes, sorry voor de storing, gelukkig kan je cadeau er nu weer tegen voor je volgende maand-verjaar-dagen!)",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 13,
            backgroundImage: null,
            title: "",
            content: "",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 14,
            backgroundImage: null,
            title: "",
            content: "",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 15,
            backgroundImage: null,
            title: "Woef woef woef",
            content: "Jaja, niet enkel Woolly zou graag bij jou zitten. Florre zou ook niets liever doen gok ik, jammer dat hij dat niet mag, maar hij zit alvast klaar in de zetel ;)",
            images: [
                "/cdn/images/messages/20210115/florre.jpg",
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 16,
            backgroundImage: null,
            title: "Extra love!",
            content: "Dit was jij vijf maanden geleden, dit ben ik vandaag. Extra liefde voor mijn liefste. xxx",
            images: [
                "/cdn/images/messages/20210116/l.gif",
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 17,
            backgroundImage: null,
            title: "",
            content: "",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 18,
            backgroundImage: null,
            title: "",
            content: "",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 19,
            backgroundImage: null,
            title: "",
            content: "",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 20,
            backgroundImage: null,
            title: "Dubbel feestje!",
            content: "Woehoeeee feestjeeee! Vandaag kennen we elkaar al een half jaar! En je bent nog steeds de meest geweldige persoon die ik al heb ontmoet. Ik hou van jou snoesie! En speciaal om dat te vieren heb ik er ook eigenhandig voor gezorgd dat Trump vandaag uit het Witte Huis wordt gezet! Toeval, dacht het niet!",
            images: [
            ],
        },
        {
            year: 2021,
            month: 1,
            day: 21,
            backgroundImage: null,
            title: "",
            content: "",
            images: [
            ],
        },
        {
            year: 2021,
            month: 2,
            day: 11,
            backgroundImage: null,
            title: "Mijn beste vriend",
            content: "Het is vandaag precies een half jaar geleden dat we vriendjes werden op Facebook. Nu ben je veel meer dan dat. Je bent niet enkel mijn leukste Facebook-vriend, mijn vriendin, mijn liefje, mijn snoesie, mijn grote liefde, maar ook mijn allerbeste vriend. Ik hou enorm veel van jou en ik zal er altijd voor jou zijn liefje.",
            images: [
            ],
        },
    ],
}
// 2021-01-18 - blue monday
export default data;
