/** @jsx jsx */

import React from 'react';
import { jsx, css } from '@emotion/core';
import View from './View';
import Spacer from 'orbiter-web-components/src/components/spacer/Spacer';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactPhotoCollageContainer from '../react-photo-collage/react-photo-collage-container';
import { buttonCss } from './Home';

class Album extends React.Component<{
    doneWithAlbum: () => void,
    images: string[],
}, {
}> {
    
    public render() {

        const setting = {
            width: '600px',
            height: ['150px', '150px'],
            layout: [3, 4],
            showNumOfRemainingPhotos: true
        };

        return (
            <View>
                <Spacer height="20px" />
                <div css={css`margin-left: auto; margin-right: auto; display: inline-block;`}>
                    <ReactPhotoCollageContainer {...setting} photos={this.props.images.map(i => { return { src: i } })} />
                </div>
                <Spacer height="20px" />
                <button css={buttonCss} onClick={() => this.props.doneWithAlbum()}>Terug</button>
            </View>
        )
    }

}

export default Album;
