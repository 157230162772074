import data, { IDay } from "./data";

let previousGreetingMinute = 0;
let previousGreeting = "";
export function greetingGenerator(): string{
    const today = new Date();
    const hour = today.getHours();
    const minute = today.getMinutes();
    if(previousGreetingMinute == minute){
        return previousGreeting;
    }
    previousGreetingMinute = minute;
    let greeting = "";
    if(hour > 21 || hour < 6){
        greeting = "Goede nacht " + nameGenerator() + "!";
    }
    else if(hour < 12){
        greeting = "Goeie morgen " + nameGenerator() + "!";
    }
    else if(hour < 17){
        greeting = "Goeie middag " + nameGenerator() + "!";
    }
    else if(hour < 21){
        greeting = "Goede avond " + nameGenerator() + "!";
    }else{
        greeting = "Hoi " + nameGenerator() + "!";
    }
    previousGreeting = greeting;
    return greeting;
}

export function greetingDescriptionGenerator(): string{
    return "Een héél gelukkige verjaardag!";
}

export function nameGenerator(): string{
    const names = ["schat", "snoesie", "patatje", "schatje", "liefje", "lieverd", "cutie pie"];
    return names[Math.floor(Math.random() * names.length)];
}

export function getToday(): IDay{
    const today = (window as any)?.dateOverride ? (window as any).dateOverride : new Date();
    for (let index = 0; index < data.days.length; index++) {
        const day = data.days[index];
        if(day.month == today.getMonth()+1 && day.year == today.getFullYear() && today.getDate() == day.day){
            return day;
        }
    }
    return data.fallback;
}